import React, { useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Viewing from "../components/viewing";
import Objects from "../components/objects";

// Patterns
import PatternFirst from "../images/pattern_01.png";
import Arrow from "../images/icons/arrow_forward.svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Swiper core and required modules
import SwiperCore, {
  Thumbs,
  FreeMode,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";

// install Swiper modules
SwiperCore.use([Thumbs, FreeMode, EffectFade, Navigation, Pagination]);

export default function Infrastructure({
  data: {
    page,
    infrastucture,
    contacts,
    forms,
    header,
    footer,
    socials,
    copyright,
    user,
    cookies,
  },
}) {
  const wpContent = page.nodes[0];
  const is = infrastucture.nodes[0].blockInfrastuctureObjects;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [buttonPrevText, setButtonPrevText] = useState(null);
  const [buttonNextText, setButtonNextText] = useState(null);

  const changePrevText = (text) => setButtonPrevText(text);
  const changeNextText = (text) => setButtonNextText(text);

  const ref = useRef(null);

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };

  // Second Slider
  const [thumbsSwiperSecond, setThumbsSwiperSecond] = useState(null);

  const [buttonPrevTextSecond, setButtonPrevTextSecond] = useState(null);
  const [buttonNextTextSecond, setButtonNextTextSecond] = useState(null);

  const changePrevTextSecond = (text) => setButtonPrevTextSecond(text);
  const changeNextTextSecond = (text) => setButtonNextTextSecond(text);

  const refSecond = useRef(null);

  const goNextSecond = () => {
    if (refSecond.current !== null && refSecond.current.swiper !== null) {
      refSecond.current.swiper.slideNext();
    }
  };

  const goPrevSecond = () => {
    if (refSecond.current !== null && refSecond.current.swiper !== null) {
      refSecond.current.swiper.slidePrev();
    }
  };

  forms = forms.nodes[0]
  contacts = contacts.nodes[0]

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={forms}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.nodes[0].locale.locale}
      uri={page.nodes[0].uri}
    >
      <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
      <header className="head">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          <ol className="breadcrumb__list">
            {/* {wpContent.seo.breadcrumbs.map((value, i) => (
              <li key={i} className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={`/ru${value.url}`}
                  dangerouslySetInnerHTML={{
                    __html: value.text,
                  }}
                ></Link>
              </li>
            ))} */}
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to="/ru/"
              >Главная</Link>
            </li>
            <li className="breadcrumb__item">
              <Link
                activeClassName="breadcrumb__link__active"
                className="breadcrumb__link"
                to={`/ru${wpContent.seo.breadcrumbs[1].url}`}
                dangerouslySetInnerHTML={{
                  __html: wpContent.seo.breadcrumbs[1].text,
                }}
              ></Link>
            </li>
          </ol>
        </nav>
        <h1>{wpContent.pageInfrastructure.infrasructureObjectsTitle}</h1>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </header>
      <div className="mainImage structure">
        <GatsbyImage
          image={
            is.infrasructureObjectsImg.localFile.childImageSharp.gatsbyImageData
          }
          alt={is.infrasructureObjectsImg.altText || ``}
        />
        {is.infrasructureObjects.map((value, i) => (
          <Objects value={value} index={i} />
        ))}
      </div>
      <div
        className="textContent"
        dangerouslySetInnerHTML={{
          __html: wpContent.pageInfrastructure.infrasructureObjectsDesc,
        }}
      ></div>
      <div id="swiperBoxFirst" className="gidBox swiperBox">
        <div className="swiperBox-pagination"></div>
        <div className="gidBox-content">
          <div className="descSwiper">
            <Swiper
              effect={"fade"}
              loop={true}
              speed={800}
              onSwiper={setThumbsSwiper}
              freeMode={true}
              autoHeight={false}
              watchSlidesProgress={true}
              breakpoints={{
                768: {
                  autoHeight: false,
                },
              }}
            >
              {wpContent.pageInfrastructure.infrasructureObjects.map(
                (value, i) => (
                  <SwiperSlide key={i}>
                    <strong className="title">{value.name}</strong>
                    <div
                      dangerouslySetInnerHTML={{ __html: value.description }}
                    ></div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
            <div className="swiperBox-controls">
              <button onClick={goPrev}>
                <Arrow /> {buttonPrevText}
              </button>
              <button onClick={goNext}>
                {buttonNextText} <Arrow />
              </button>
            </div>
          </div>
          <div id="mainSwiper" className="mainSwiper smallStyle">
            <Swiper
              ref={ref}
              pagination={{
                clickable: true,
                el: "#swiperBoxFirst .swiperBox-pagination",
                renderBullet: function (index, className) {
                  return (
                    '<span class="swiperBox-pagination-item ' +
                    className +
                    '"><div class="icon">' +
                    wpContent.pageInfrastructure.infrasructureObjects[index]
                      .icon +
                    "</div>" +
                    wpContent.pageInfrastructure.infrasructureObjects[index]
                      .name +
                    "</span>"
                  );
                },
              }}
              thumbs={{ swiper: thumbsSwiper }}
              loop={true}
              speed={800}
              spaceBetween={16}
              slidesPerView={"auto"}
              onSlideChange={(swiper) => {
                swiper.realIndex === 0
                  ? changePrevText(
                      wpContent.pageInfrastructure.infrasructureObjects[
                        swiper.loopedSlides - 1
                      ].name
                    )
                  : changePrevText(
                      wpContent.pageInfrastructure.infrasructureObjects[
                        swiper.realIndex - 1
                      ].name
                    );

                swiper.realIndex === swiper.loopedSlides - 1
                  ? changeNextText(
                      wpContent.pageInfrastructure.infrasructureObjects[0].name
                    )
                  : changeNextText(
                      wpContent.pageInfrastructure.infrasructureObjects[
                        swiper.realIndex + 1
                      ].name
                    );
              }}
              breakpoints={{
                1300: {
                  spaceBetween: 36,
                },
                768: {
                  spaceBetween: 24,
                },
              }}
            >
              {wpContent.pageInfrastructure.infrasructureObjects.map(
                (value, i) => (
                  <SwiperSlide key={i}>
                    <GatsbyImage
                      image={
                        value.img.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={value.img.altText || ``}
                    />
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <h2>{wpContent.pageInfrastructure.infrasructureNearObjectsTitle}</h2>
      <div className="mainImage structure mapStyle">
        <GatsbyImage
          image={
            wpContent.pageInfrastructure.infrasructureNearObjectsImg.localFile
              .childImageSharp.gatsbyImageData
          }
          alt={
            wpContent.pageInfrastructure.infrasructureNearObjectsImg.altText ||
            ``
          }
        />
        {wpContent.pageInfrastructure.infrasructureNearObjects.map((value, i) => (
          <Objects value={value} index={i} />
        ))}
      </div>
      <div
        className="textContent"
        dangerouslySetInnerHTML={{
          __html: wpContent.pageInfrastructure.infrasructureNearObjectsDesc,
        }}
      ></div>
      <div id="swiperBoxSecond" className="gidBox swiperBox">
        <div className="swiperBox-pagination"></div>
        <div className="gidBox-content">
          <div className="descSwiper">
            <Swiper
              effect={"fade"}
              loop={true}
              speed={800}
              autoHeight={false}
              onSwiper={setThumbsSwiperSecond}
              freeMode={true}
              watchSlidesProgress={true}
              breakpoints={{
                768: {
                  autoHeight: false,
                },
              }}
            >
              {wpContent.pageInfrastructure.infrasructureNearObjects.map(
                (value, i) => (
                  <SwiperSlide key={i}>
                    <strong className="title">{value.name}</strong>
                    <div
                      dangerouslySetInnerHTML={{ __html: value.description }}
                    ></div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
            <div className="swiperBox-controls">
              <button onClick={goPrevSecond}>
                <Arrow /> {buttonPrevTextSecond}
              </button>
              <button onClick={goNextSecond}>
                {buttonNextTextSecond} <Arrow />
              </button>
            </div>
          </div>
          <div id="mainSwiper" className="mainSwiper smallStyle">
            <Swiper
              ref={refSecond}
              pagination={{
                clickable: true,
                el: "#swiperBoxSecond .swiperBox-pagination",
                renderBullet: function (index, className) {
                  return (
                    '<span class="swiperBox-pagination-item ' +
                    className +
                    '"><div class="icon">' +
                    wpContent.pageInfrastructure.infrasructureNearObjects[index]
                      .icon +
                    "</div>" +
                    wpContent.pageInfrastructure.infrasructureNearObjects[index]
                      .name +
                    "</span>"
                  );
                },
              }}
              thumbs={{ swiper: thumbsSwiperSecond }}
              loop={true}
              speed={800}
              spaceBetween={16}
              slidesPerView={"auto"}
              onSlideChange={(swiper) => {
                swiper.realIndex === 0
                  ? changePrevTextSecond(
                      wpContent.pageInfrastructure.infrasructureNearObjects[
                        swiper.loopedSlides - 1
                      ].name
                    )
                  : changePrevTextSecond(
                      wpContent.pageInfrastructure.infrasructureNearObjects[
                        swiper.realIndex - 1
                      ].name
                    );

                swiper.realIndex === swiper.loopedSlides - 1
                  ? changeNextTextSecond(
                      wpContent.pageInfrastructure.infrasructureNearObjects[0]
                        .name
                    )
                  : changeNextTextSecond(
                      wpContent.pageInfrastructure.infrasructureNearObjects[
                        swiper.realIndex + 1
                      ].name
                    );
              }}
              breakpoints={{
                1300: {
                  spaceBetween: 36,
                },
                768: {
                  spaceBetween: 24,
                },
              }}
            >
              {wpContent.pageInfrastructure.infrasructureNearObjects.map(
                (value, i) => (
                  <SwiperSlide key={i}>
                    <GatsbyImage
                      image={
                        value.img.localFile.childImageSharp.gatsbyImageData
                      }
                      alt={value.img.altText || ``}
                    />
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <Viewing forms={forms} contacts={contacts} />
    </Layout>
  );
}

export const query = graphql`
  {
    copyright: allWpRepeatableBlocks(filter: { slug: { eq: "copyrights" } }) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(
      filter: { slug: { eq: "ugoda-korystuvacha" } }
    ) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(
      filter: { slug: { eq: "polityka-cookies" } }
    ) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    page: allWpPage(
      filter: {
        locale: { locale: { eq: "ru" } }
        slug: { eq: "infrastructure" }
      }
    ) {
      nodes {
        uri
        slug
        seo {
          title
          metaDesc
          breadcrumbs {
            text
            url
          }
        }
        locale {
          locale
        }
        title
        pageInfrastructure {
          infrasructureNearObjectsDesc
          infrasructureNearObjectsTitle
          infrasructureObjectsDesc
          infrasructureObjectsTitle
          infrasructureNearObjects {
            description
            icon
            name
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          infrasructureNearObjectsImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          infrasructureNearObjectsImgMobile {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
          infrasructureObjects {
            description
            icon
            name
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          infrasructureObjectsImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
    infrastucture: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "ru" } }
        slug: { eq: "infrastruktura-na-terytoriyi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        blockInfrastuctureObjects {
          infrasructureObjectsDesc
          infrasructureObjectsTitle
          sectionInfrastructureButtonMoreLink
          sectionInfrastructureButtonMoreText
          infrasructureObjects {
            description
            icon
            name
            img {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          infrasructureObjectsImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
    contacts: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "ru" } }
        slug: { eq: "kontaktna-informacziya" }
      }
    ) {
      nodes {
        slug
        title
        blockContacts {
          contactAddressText
          contactAddressTitle
          contactEmailAdressText
          contactEmailAdressTitle
          contactPhonesListTitle
          fieldGroupName
          scheduleListTitle
          contactPhonesList {
            phoneNumber
          }
          scheduleList {
            item
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "ru" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        slug
        title
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          fieldGroupName
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
        locale {
          locale
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "ru" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
